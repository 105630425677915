#Footer{
    padding-bottom: 5rem;

    .container {
        flex-direction: column;
        height: 300px;
        justify-content: space-evenly;
    }

    .SectionHeader {
        width: 100%;
        padding: 0;
    }

    #medias {
        margin-bottom: 5rem;

        .media {
            display: inline-block;
            margin: 0 2rem;

            a {
                display: block;

                i {
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    color: transparent;
                    display: block;
                    font-size: 4.8rem;
                    height: 1em;
                    overflow: hidden;
                    width: 1em;
                }
            }
        }
    }

    p, address, strong,a  {
        font-size: 2rem;
        font-style: normal;
        font-weight: normal;
        line-height: 1.1;
        margin-bottom: 2rem;
    }

    #getSmarter {
        text-align: center;
        margin-bottom: 7rem;        
    }

    .cta {
        color: #ff8a89;
    }
    #copyright {
        text-align: center;
        
        p, address {
            display: inline;
        }
    }
}