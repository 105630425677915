.SectionHeader{
    padding: 0rem 0px 6rem 0px;
    position: relative;


    $height: calc((1em + 7.5px * 2) * 5);
    &:before{
        background-attachment: fixed;
        background-image: url("https://s3.us-west-2.amazonaws.com/citydata.media/citydata.ai/images/dist/CITYDATA_background_flow_lines.webp");
        background-repeat: no-repeat;
        background-size: cover;
        content: '';
        display: block;
        height: $height;
        margin-bottom: 0rem;     
        width: 100%;
    }

    &:after{
        background-image: url('https://s3.us-west-2.amazonaws.com/citydata.media/citydata.ai/images/CITYDATA_River_Separators/dist/CITYDATA_River_Separator_1_White_transparent.webp');
        background-repeat: no-repeat;
        background-size: 101% 101%;
        content: '';
        display: block;
        height: $height;
        margin-bottom: 6rem;     
        position: absolute;
        top: 0;
        width: 100%;
    }

    .headlineAndButton {
        display: flex;
        align-items: center;
        padding-top: 2rem;

        .cta {
            margin-top: 3px;
            margin-left: 2rem;            
        }
    }

    .Headline_1{
        // font-size: 3rem; // ns
        // line-height: 1.5; // ns
        font-size: 5.2rem;
        line-height: 1.5;
        text-transform: uppercase;
        font-family: 'Oswald';
        width: fit-content;
        display: inline;

        .SectionHeaderLogoImage {
            height: 77px;
            vertical-align: bottom;
            margin-right: 10px;
        }
    }
    .Headline_2{
        font-size: 4.1rem;
        font-weight: normal;
        font-family: 'Oswald';
        line-height: 1;
    }  
}


.hasSectionContent:nth-child(even) {
    .SectionHeader:after {
        background-image: url('https://s3.us-west-2.amazonaws.com/citydata.media/citydata.ai/images/CITYDATA_River_Separators/dist/CITYDATA_River_Separator_2_White_transparent.png');
    }
}

#Citychat,
#Cityworks,
#CitySDK,
#CityAI {
    .SectionHeader {
        padding-top: 5rem;

        &:before,
        &:after {
            display: none;
        }    
    }
}
@media screen and (max-width: 1000px) {
    #Citychat,
    #Cityworks,
    #CitySDK,
    #CityAI {
        .SectionHeader {
            padding-top: 3rem;
        }
    }

    .SectionHeader .Headline_1 {
        line-height: 1;
    }
}
@media screen and (max-width: 768px) {
    .SectionHeader {
        &:before, 
        &:after {
            height: calc((1em + 15px) * 2.5);
        } 

        .Headline_2 {
            font-size: 3.5rem;
        }
    }
}