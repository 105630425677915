@font-face {
  font-family: "Oswald";
  src: local("Oswald"),
    url("./fonts/Oswald/static/Oswald-Regular.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto-Thin"),
    url("./fonts/Roboto/Roboto-Thin.ttf") format("truetype");
  font-weight: lighter;
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto-Light"),
    url("./fonts/Roboto/Roboto-Light.ttf") format("truetype");
  font-weight: light;
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto-Regular"),
    url("./fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  font-weight:400;
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto-Medium"),
    url("./fonts/Roboto/Roboto-Medium.ttf") format("truetype");
  font-weight:500;
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto-Bold"),
    url("./fonts/Roboto/Roboto-Bold.ttf") format("truetype");
  font-weight:bold;
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto-Black"),
    url("./fonts/Roboto/Roboto-Black.ttf") format("truetype");
  font-weight:900;
}

* {
  font-size: 10px;
  line-height: 0.8;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
}

body {
  font-family: 'Roboto', sans-serif;

  &.freeze {
    overflow: hidden;
  }
}

a,p,span,button, ol, li {
  font-size: 1.6rem;
}

a {
  text-decoration: none;
}

.container {
  max-width: 1440px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  box-sizing: border-box;
  width: 100%;
  margin: auto;
}

.scroller {
  position: relative;
}


.hasContent + .devices {
  // padding-left: 2rem;
  width: 100%;
}

// .phoneMockup {
//   position: relative;
//   width: 100%;
//   max-width: 100%;

//   .sizeReference {
//       position: relative;

//       img {
//           opacity: 0;
//           width: 100%;
//       }
//   }

//   .collection {
//       position: relative;

//       .frame {
//           background-image: url(https://s3.us-west-2.amazonaws.com/citydata.media/citydata.ai/images/dist/CITYDATA_CITYCHAT_iPhone_transparent.png);
//           background-position: top;
//           background-repeat: no-repeat;
//           background-size: contain;
//           font-size: 1rem;
//           height: 100%;
//           overflow: hidden;
//           padding-bottom: 30px;
//           position: absolute;
//           width: 100%;
//           z-index: 10;
//       }

//       .images {
//           box-sizing: border-box;
//           height: 92%;
//           margin-top: 1.6rem;
//           overflow: hidden;
//           padding: 0rem 1.5rem 0rem;
//           position: absolute;
//           top: 0;
//           width: 100%;
//           z-index: 1;

//           .hasImage {
//               width: 100%;

//               img {
//                   width: 100%;
//               }
//           }
//       }
//   }
// }

@media screen and (max-width:500px) {
  html {
    font-size: 8px;
  }
}