#SummaryContent{
    li, p, a, span, strong {
        margin-bottom: 1rem;
        font-size: 2rem;
        line-height: 1.2;
    }

    #Intro {
        padding-bottom: 0;        
    }

    .main {
        width: 100%;

        #description {
            margin-bottom: 6rem;            
        }

        #sections {
            list-style-position: inside;
            list-style-type: none;
    
            .section {
                font-size: 2rem;
                line-height: 1.2;
                display: inline-block;
                width: 20%;
                box-sizing: border-box;
                padding: 0 0.5rem;

                a {
                    font-size: inherit;
                    background-color: #1c4362;
                    text-decoration: none;
                    color: #fff;
                    display: block;
                    padding: 8rem 1.5rem;
                    box-sizing: border-box;
                    transition: all .15s ease-in-out;
                    margin: 0;                    

                    &:hover {
                        background: #73c6bd;                        
                    }
                }
            }
        }
    }
}