#LoaderAnimation{
    position: fixed;
    z-index: 100100102;
    background: white;
    width: 100vw;

    transition: all .8s linear;
    transform: scale(1);
    opacity: 1;

    &.out {
        transform: scale(10);
        opacity: 0;
        
        svg {
            filter: blur(10px);
        }
    }

    #hasIntroLogo {
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            height: 50%;

            path {
                transition: all .8s linear;
                fill: none;
                stroke: rgb(247, 105, 99);
                stroke-width: 1px;
                stroke-dasharray: 1000px;                
            }
        }
    }
}