#marketplace{
    #title {
        font-size: 10rem;
        font-size: 10rem;
        display: inline;
        font-family: Oswald;
        font-size: 5.2rem;
        line-height: 1.5;
        text-transform: uppercase;
        width: -webkit-fit-content;
        width: -moz-fit-content;        
    }
    
    .content {
        width: 130%;
        overflow: scroll;    
    }
    
    ul {
        width: 4260px;
        margin-bottom: 20px;
    }
    
    li.offer {
        display: inline-block;
        width: 500px;
        height: 350px;
        border: solid 6px #8fcac6;
        vertical-align: top;
        border-radius: 5px;
        padding: 10px;
        box-sizing: border-box;
        margin-right: 30px;
        margin-bottom: 30px;    
    
        h2 {
            display: inline;
            font-family: Oswald;
            font-size: 3rem;
            line-height: 1;
            text-transform: none;        
        }
    
        .details {
            margin-top: 5px;
    
            .owner {
                margin-bottom: 20px;
                
                .name {
                    display: inline;
                    font-family: Oswald;
                    font-size: 2rem;
                    line-height: 1;
                    text-transform: none;
                }
            }
        
            .included {
                margin-bottom: 20px;
        
                ul {
                    list-style: none;
        
                    li {
                        line-height: 1.5;
                    }
                }
            }
        
            .description {
                margin-bottom: 20px;
        
                p {
                    line-height: 1.2;
                    height: 60px;
                }
            }
        
            .checkout {
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;
        
                button {
                    color: white;
                    background: #8fcac6;
                    padding: 10px;
                    border-radius: 3px;
                }
                
                .condition {
                    color: #8fcac6;
                    margin-left: 5px;
                }
            }
        }
    }    
}