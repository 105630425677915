#cityops{
    .devices {
        max-width: 55%;
        position: relative;        
    }
    
    .phoneMockup {
        .collection {            
            .images {
                // height: 89%;
                // margin-top: 0;
                // padding: 4rem 1.2rem 0rem;
                // border-radius: 0;

                .hasImage {
                    width: 100%;
    
                    // position: absolute;
                    // top: 0;
                    // left: 0;
                    // opacity: 0;
                    // transition: opacity .4s ease-in-out;
                    display: none;

                    &.active {
                        display: block;
                        // opacity: 1;
                    }

                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 1000px) {
    #cityops {
        .devices {
            max-width: 100%;
        }
    }
}