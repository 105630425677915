#Pricing{
    padding-bottom: 6rem;

    .hasContent {
        width: 100%;
    }

    #Prices {
        width: 100%;
    }
    .hasPlans {
        display: flex;
        justify-content: space-between;    
        width: 100%;
        
        li {
            list-style-type: none;
        }

        .plan {
            text-align: center;
            width: 25%;

            .hasImage {
                margin-bottom: 5rem;

                img {
                    height: 177px;
                    max-width: 100%;
                }
            }

            .pricing {
                font-size: 8rem;
                font-weight: lighter;
                margin-bottom: 1.5rem;
            }

            .title {
                font-size: 2rem;
                font-weight: lighter;
                margin-top: 4rem;
                margin-bottom: 1rem;
            }
            
            .title_2 {
                font-size: 2rem;
                font-weight: lighter;
                margin-bottom: 3.5rem;
            }

            .subtitle {
                background-color: #67b68c;
                border-radius: 5px;
                color: #fff;
                font-size: 2rem;
                font-weight: normal;
                padding: 2rem 0;
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    #Pricing .hasPlans .plan {
        .subtitle {
            font-size: 1.8rem;        
        }
    }
}

@media screen and (max-width: 1000px) {
    #Pricing .hasPlans .plan {
        width: 32%;
    }
}

@media screen and (max-width: 768px) {
    #Pricing {
        #Prices {
            padding-bottom: 0;
        }

        .hasPlans {
            flex-direction: column;
    
            .plan {
                width: 100%;
                margin-bottom: 10rem;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    #Pricing {
        #Prices {
            padding-bottom: 0;
        }

        .hasPlans {
            flex-direction: column;
    
            .plan {
                &:last-child {
                    margin: 0;
                }

                .hasImage {
                    width: 45%;
                    height: 180px;
                    margin-bottom: 0;
                    display: flex;
                    align-items: center;
                    float: left;
                }
    
                .subtitle {
                    background: white;
                    color: #67b68c;
                    line-height: 1.5;
                    padding: 0;                
                }
            }
        }
    }
}