.hasSteps {
    // display: flex;
    // justify-content: space-between;
    margin-top: -5rem !important;
    overflow: hidden;
    
    .step {
        list-style-type: none;
        width: fit-content;
        margin: auto;

        &:nth-child(even) article {
            text-align: right;

            div {
                &:first-child {
                    width: 42%;
                }
                &:last-child {
                    width: 25%;
                }
            }
        }

        &:nth-child(odd) article {
            text-align: left;

            div {
                &:first-child {
                    width: 25%;
                }
                &:last-child {
                    width: 42%;
                }
            }
        }

        .hasImage {
            padding: 0 !important;
            // width: 36rem !important;
        }

        div {
            // width: 72rem !important;
            box-sizing: border-box;
            display: inline-block;
            padding: 0 2rem;
            width: 33%;
            vertical-align: middle;
        }

        img {
            max-width: 100%;
        }

        .head, .title, .subtitle {
            font-size: 2rem !important;
            font-weight: normal;
            line-height: 1;
            // max-width: 80%;            
        }

        .title {
            font-size: 2.5rem !important;
            margin-bottom: 1rem;
        }
        .head {
            margin-bottom: 1rem;
        }
    }
}

@media screen and (max-width: 768px){
    .hasSteps .step {
        margin-bottom: 10rem !important;

        &:nth-child(even) article {
            text-align: right;

            div {
                &:first-child {
                    width: 60%;
                }
                &:last-child {
                    width: 0%;
                }
            }
        }

        &:nth-child(odd) article {
            text-align: left;

            div {
                &:first-child {
                    width: 0%;
                }
                &:last-child {
                    width: 60%;
                }
            }
        }
    }
}

@media screen and (max-width: 768px){
    .hasSteps .step {
        div {
            width: 100% !important;
            text-align: left;
        }

        img {
            margin-bottom: 2rem;            
        }

        &:nth-child(odd) article {
            // display: flex;
            // flex-direction: column-reverse;
        }
        &:nth-child(even) article {
            display: flex;
            flex-direction: column-reverse;
        }
    }
}