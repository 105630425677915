.CookieConsent {
    background-color: #030D1C !important;
    max-width: 535px!important;
    width: 100% !important;
    flex-direction: column;
    margin: auto;
    left: 12px !important;
    bottom: 15px !important;
    height: 20vh;
    border-radius: 5px;
    flex-wrap: nowrap !important;
    min-height: 215px !important;
}
.CookieConsent #rcc-confirm-button {
    background-color: #73C6BD !important;
    color: white !important;

    margin:auto !important;
    display: block;
    border-radius: 5px !important;
    font-size: 2rem;
    line-height: 1.1;
    padding: 1rem 1rem !important;
    width: -moz-fit-content;
    width: fit-content;
}
.CookieConsent > div {
    font-size: 2rem;
    line-height: 1.1;
    flex: initial !important;
    width: 100%;
    margin: 0 !important;
    padding: 15px;
    box-sizing: border-box;
}

@media screen and (max-width:547px){
    .CookieConsent {
        left: 0px !important;
        bottom: 0px !important;
    }

    .CookieConsent #rcc-confirm-button {
        transform: translateY(-20px);
    }
}