#SmallSearch{
    // background: #44546a; // ns
    background-color: #142945;
    background-image: url('https://s3-us-west-2.amazonaws.com/citydata.media/citydata.ai/images/dist/CITYDATA-SEARCH-BACKGROUND.webp');
    background-size: cover;
    padding: 5rem 0;
    overflow: hidden;
    position: relative;
    color: #fff;
    height: 400px;

    .SectionHeader:before,
    .SectionHeader:after {
        display: none;
    }

    .citySearch {
        position: relative;
        width: 100%;
        left: 0;
        height: 0%;
        z-index: 10;
        
        .holder {
            width: 50%;
            height: 100%;

            display: flex;
            align-items: center;
            // justify-content: center;
        }

        .cityFinder {
            width: 100%;

            label {
                display: block;
                margin-bottom: 2rem;
            }

            input {
                outline: none;
                background: transparent;
                display: block;
                height: 70px;
                border: solid 4px #97d5ce;
                border-radius: 10px;
                width: 500px;
                text-align: center;
                color: #fff;
                font-size: 2rem;
                font-family: 'Roboto';
                width: 100%;
                text-align: left;
                text-indent: 2rem;
                box-sizing: border-box;

                &:focus {
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                    
                    & + .hasDatalist {
                        display: block;
                    }
                }
            }

            ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: #fff;
                text-align: left;
                text-indent: 2rem;
            }
        }
    }

    #globeViz {
        padding-left: 25%;
        box-sizing: border-box;
        width: 100%;
        overflow: hidden;
        position: absolute;
        top: -1%;
        z-index: 1;
    }

    #citiesDetails {
        .topic{
            margin-bottom: 2rem;

            table {
                width: 100%;
            }
        }

        h2 {
            color: #f76963;
            font-size: 2rem;
            padding-top: 2rem;
            padding-bottom: 0.5rem;

            strong {
                display: block;
                color: white;
                font-size: 2rem;
                font-weight: normal;
                font-family: 'Roboto';
                line-height: 1.5;
            }
        }

        td {
            font-size: 2rem;
            line-height: 1.2;
            vertical-align: top;

            .SignIn {
                float: right;
                padding: 0.5rem 1rem;
                margin-top: 11px;
            }

            &:first-child {
                color: #97d5ce;
            }
            
            &:nth-child(2n) {
                padding-left: 2rem;
            }
        }
    }

    .findComponents {
        position: relative;
    
        ::-webkit-scrollbar {
            width: 10px;
        }
           
        ::-webkit-scrollbar-track {
            background-color: #a6e7e0;
            border-radius: 20px;
        }
           
        ::-webkit-scrollbar-thumb {
            height: 18px;
            background-color: #142945;
            outline: none;
            border-radius: 10px;
        }   

        .hasDatalist {
            background: #97d5ce;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            border: solid 1rem #97d5ce;
            box-sizing: border-box;
            display: none;
            margin-top: 0;
            max-height: 200px;
            overflow-y: scroll;
            position: absolute;
            width: 100%;

            &:hover {
                display: block;
            }

            .item {
                line-height: 1;

                a {
                    color: #fff;
                    display: block;
                    line-height: 1.5;
                    background: transparent;
                    width: 100%;
                    text-align: left;
                    text-indent: 1rem;
                    border-radius: 5px;
                    font-size: 2rem;

                    &:hover {
                        background-color: #142945;
                    }
                }
            }           
        }
    }

    .onMobile {
        display: none;
    }
}
@media screen and (max-width: 1000px) {
    #Search {
        display: flex;
        flex-direction: column-reverse;

        .citySearch .holder {
            width: 100%;
        }

        #globeViz {
            position: relative;
            transform: none !important;
            padding: 0px;            
        }

        > .container {
            z-index: 40;
        }

        .onDesktop {
            display: none;
        }
        .onMobile {
            display: block;
        }
    }    
}
@media screen and (max-width: 600px) {
    #Search {
        #citiesDetails {
            td {
                width: 100%;
                display: block;
                padding: 0 !important;
            }
        }
    }
}