#Search{
    // background: #44546a; // ns
    background-color: #142945;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='400' height='400' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23172F4F' stroke-width='1'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23193458'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E");
    padding: 5rem 0;
    overflow: hidden;
    position: relative;
    color: #fff;
    min-height: 100vh;

    .container {
        height: 100%;
        display: block;        
        position: relative;
        z-index: 2;        
        padding: 0;
    }

    .buttonAboveInput {
        background: rgb(143, 202, 198);
        border-radius: 5px;
        line-height: 1.1;
        padding: 1rem;
        width: fit-content;
        float: right;
        margin: 15px 12px -90px 0px;
        color: white;
        font-size: 18px;
        cursor:pointer;
        position:relative;
        z-index:1000;
    }

    .SectionHeader:before,
    .SectionHeader:after {
        display: none;
    }

    .citySearch {
        position: relative;
        width: 100%;
        left: 0;
        height: 70%;
        z-index: 10;
        
        .holder {
            width: 70%;
            height: 100%;

            display: flex;
            align-items: center;
            // justify-content: center;
        }

        .cityFinder {
            width: 100%;

            label {
                display: block;
                margin-bottom: 2rem;
            }

            input {
                outline: none;
                background: transparent;
                display: block;
                height: 70px;
                border: solid 4px #97d5ce;
                border-radius: 10px;
                width: 500px;
                text-align: center;
                color: #fff;
                font-size: 2rem;
                font-family: 'Roboto';
                width: 100%;
                text-align: left;
                text-indent: 2rem;
                box-sizing: border-box;
                position: relative; 
                z-index: 30;

                &:focus {
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                    
                    & + .hasDatalist {
                        display: block;
                    }
                }
            }

            ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: #fff;
                text-align: left;
                text-indent: 2rem;
            }
        }
    }

    #globeViz {
        padding-left: 25%;
        box-sizing: border-box;
        width: 100%;
        overflow: hidden;
        position: absolute;
        top: 6%;
        z-index: 1;
    }

    #citiesDetails {
        .topic{
            margin-bottom: 2rem;

            table {
                width: 100%;
            }
        }

        h2 {
            color: #f76963;
            font-size: 2rem;
            padding-top: 2rem;
            padding-bottom: 0.5rem;

            strong {
                display: block;
                color: white;
                font-size: 2rem;
                font-weight: normal;
                font-family: 'Roboto';
                line-height: 1.5;
            }
        }

        td {
            font-size: 2rem;
            line-height: 1.2;
            vertical-align: top;

            a {
                background: #8fcac6;
                border-radius: 5px;
                line-height: 2.1;
                width: -moz-fit-content;
                width: fit-content;
                color: white;
                font-size: 18px;
                cursor: pointer;
                position: relative;
                z-index: 1000;
                padding: 3px 10px 5px;
                font-weight: bold;
            }

            .SignIn {
                float: right;
                padding: 0.5rem 1rem;
                margin-top: 11px;
            }

            &:first-child {
                color: #97d5ce;
            }
            
            &:nth-child(2n) {
                padding-left: 2rem;
            }
        }
    }

    .findComponents {
        position: relative;
    
        ::-webkit-scrollbar {
            width: 10px;
        }
           
        ::-webkit-scrollbar-track {
            background-color: #a6e7e0;
            border-radius: 20px;
        }
           
        ::-webkit-scrollbar-thumb {
            height: 18px;
            background-color: #142945;
            outline: none;
            border-radius: 10px;
        }   

        .hasDatalist {
            background: #97d5ce;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            border: solid 1rem #97d5ce;
            box-sizing: border-box;
            display: none;
            margin-top: 0;
            max-height: 45rem;
            overflow-y: scroll;
            position: absolute;
            width: 100%;

            &:hover {
                display: block;
            }

            .item {
                line-height: 1;

                button {
                    color: #fff;
                    display: block;
                    line-height: 1.5;
                    background: transparent;
                    width: 100%;
                    text-align: left;
                    text-indent: 1rem;
                    border-radius: 5px;
                    font-size: 2rem;

                    &:hover {
                        background-color: #142945;
                    }
                }
            }           
        }
    }

    .onMobile {
        display: none;
    }
}
@media screen and (max-width: 1000px) {
    #Search {
        display: flex;
        flex-direction: column-reverse;

        .citySearch .holder {
            width: 100%;
        }

        #globeViz {
            position: relative;
            transform: none !important;
            padding: 0px;            
        }

        > .container {
            z-index: 40;
        }

        .onDesktop {
            display: none;
        }
        .onMobile {
            display: block;
        }
    }    
}
@media screen and (max-width: 600px) {
    #Search {
        #citiesDetails {
            tr {
                margin-bottom: 6px;
                display: block;
            }
            
            td {
                width: 100%;
                display: block;
                padding: 0 !important;

                &:before {
                    width: 1em;
                    height: 1em;
                    display: inline-block;
                    content: "";
                    background: #97d5ce;
                    font-size: 7px;
                    border-radius: 50%;
                    vertical-align: middle;
                    margin-right: 8px;
                    margin-left: 5px;
                }

                &:last-child:before {
                    background: transparent;
                }
            }
        }
    }    
}