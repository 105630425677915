#cityai{
    padding-bottom: 6rem;

    .ProductDescription section .main .hasContent p {
        margin-bottom: 0rem;
    }

    .ProductDescription section .main .hasContent{
        max-width: 100%;
    }

    #hasDescriptions {
        width: 100%;
        display: flex;
        justify-content: space-between;
        
        .item {
            list-style-type: none;
            text-align: center;

            .title {
                font-size: 2.8rem;
                font-weight: normal;
                margin-bottom: 2rem;
            }
            .subtitle {
                color: #67b68c;
                font-size: 2rem;
                font-weight: lighter;
            }            
        }
    }
}

@media screen and (max-width: 700px) {
    #cityai {
        #hasDescriptions {
            flex-direction: column;
        }

        .item {
            &:first-child {
                margin-left: -4%;                
            }

            &:nth-child(2) {
                &:before, &:after {
                    display: block;
                    content: "";
                    width: 0px;
                    height: 100px;
                    border-left: dotted 7px #c4cfd8;
                }
        
                &:before {
                    margin: 50px auto 0;
                }
        
                &:after {
                    margin: 50px auto 0px;
                }        
            }

            &:last-child {
                img {
                    max-width: 106%;
                }
            }
        }
    }
}