#hasHighlightedCustomer {
    width: 44%;
    min-width: 530px;

    #HighlightedCustomer {
        background-image: url('../../images/svg/BLOB_Customers.svg');
        background-repeat: no-repeat;
        position: relative;
        width: 100%;
        background-size: 100%;
        background-position: center;
        min-height: 630px;
    
        #BLOB_Customers {
            opacity: 0;
            overflow: hidden;            
        }
    
        #HighlightedCustomer_content {
            height: 60%;
            left: 0;
            padding: 10rem 7vw 0 5vw;
            position: absolute;
            top: 0;
    
            .hasContent {
                color: #fff;
                display: flex;
                flex-direction: column;
                height: 80%;
                justify-content: flex-start;
                padding-top: 14rem;
    
                #endorser_photo {
                    left: 0;
                    top: 0;
                    width: fit-content;
                    margin: -30rem auto 5rem;
    
                    img {
                        border-radius: 100%;
                        height: 1em;
                        width: 1em;
                        font-size: 22rem;
                    }
                }
    
                header {
                    margin-bottom: 3rem;
                }
    
                .endorser_name {
                    font-size: 3.2rem;
                    line-height: 1.2;
                    width: fit-content;
                }
                .endorser_title {
                    font-size: 2.5rem;
                    width: fit-content;
                    line-height: 1;
                }
                .endorser_company {
                    font-size: 2rem;
                    width: fit-content;
                    font-weight: normal;
                    margin-top: 0.5rem;
                }
                .endorser_text {
                    font-size: 2rem;
                    font-weight: normal;
                    line-height: 1.2;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    $customer-width: 20rem;

    #hasHighlightedCustomer {
        min-width: initial;
        width: 100%;
 
        #HighlightedCustomer {
            min-height: auto;
            background-size: 100% auto;
            background-position: -50px 40px;
            padding-bottom: 100px;
            padding-top: 0;

            background: #fd736c;
            border-top-left-radius: 11.7rem;
            border-bottom-right-radius: 7rem;
            
            #BLOB_Customers {
                display: none;
            }
            
            #HighlightedCustomer_content {
                padding-right: 3rem;
                position: relative;

                .hasContent {
                    .endorser_name {
                        font-size: 7.59vw;
                    }
                
                    .endorser_title {
                        font-size: 4.8vw;
                    }
                }
            }
        }
    }
}