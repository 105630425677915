$customer-width: 13rem;
#Customers{
    padding-bottom: 6rem;

    #hasCustomersContent {
        display: flex;
        justify-content: space-between;
    }

    #hasCustomersList {
        // align-items: center;
        // display: flex;
        width: 56%;
        flex-direction: column;
        
        // #customersListWithTestimonials {
        //     text-align: center;

        //     .customer {
        //         display: inline-block;
        //         font-size: 16rem;
        //         height: 1em;
        //         overflow: hidden;
        //         width: 1em;

        //         .item {
        //             cursor: pointer;
        //         }
        //     }
        // }
        
        #customersList {
            text-align: left;

            .customer {
                display: inline-block;
                font-size: $customer-width;
                height: 1em;
                overflow: hidden;
                width: 1em;
            }
        }
    }

    .customer {
        padding: 0 1rem;
        box-sizing: border-box;

        .item {
            height: 100%;
            vertical-align: top;
            background: #fff;

            // display: flex;
            // align-items: center;

            figure {
                background-size: 80%;
                background-repeat: no-repeat;
                background-position: center;     
                height: 100%;           
                display: flex;
                align-items: center;
            }

            img {
                max-width: 90%;
                margin: auto;

                transition: all .15s ease-in-out;
                filter: grayscale(1);
            }

            &:hover {
                img {
                    filter: grayscale(0);
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    #Customers {
        #hasCustomersContent{
            flex-direction: column-reverse;
            width: 100%;
            padding-top: 6rem;

            #hasCustomersList {
                min-width: initial;
                width: 100%;
            }

            #hasCustomersList {
                overflow-x: scroll;
                display: block;

                #customersList {
                    flex-direction: column;

                    .customer {
                        display: inline-block;
                        font-size: #{$customer-width};
                    }

                    @for $i from 1 through 3000 {
                        &.allCustomersCount-#{$i} {
                            width: calc(#{$customer-width} * #{$i});
                        }
                    }
                }
            }
        }
    }
}