#cityparks{
    .laptopMockup {
        box-sizing: border-box;
        position: relative;
        max-width: 100%;
    
        .collection {
            position: relative;
    
            .images {
                box-sizing: border-box;
                height: 100%;
                margin-top: 1.6rem;
                overflow: hidden;
                width: 100%;
                z-index: 1;
    
                .hasImage {
                    width: 100%;
                    display: none;
                    transition: opacity .4s ease-in-out;

                    &.active {
                        display: block;
                    }

                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 1000px) {
    #cityflow .laptopMockup {
        padding: 0;
        width: 100%;
    }
}