#InternalContent{
    .title {
        font-size: 4.1rem;
        font-weight: normal;
        font-family: "Oswald";
        margin-bottom: 1.2rem;
    }

    li, p, a, span, strong {
        margin-bottom: 1rem;
        font-size: 2rem;
        line-height: 1.2;
    }

    #Intro {
        padding-bottom: 0;
    }

    #description {
        margin-bottom: 6rem;
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }

    #sections {
        list-style-type: none;

        .section {
            margin-bottom: 5rem;
        
            ul {
                margin-top: 1rem;
                list-style-position: inside;
                list-style-type: disc;                
            }

            a {
                color: #f76963;
                text-decoration: underline;                
            }

            .SectionHeader {
                &:before, &:after {
                    display: none;
                }
            }

            .hasImage {
                width: 55%;
    
                img {
                    width: 100%;
                }
            }
        }
    }
}