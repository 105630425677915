#citychat{
    .devices {
        .hasImage.toSlide {
            width: 100%;

            // position: absolute;
            // top: 0;
            // left: 0;
            // opacity: 0;
            // transition: opacity .4s ease-in-out;
            display: none;

            &.active {
                display: block;
            }

            img {
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    #citychat .devices {
        padding: 0 0rem;
    }
}