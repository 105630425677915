#Solution {
    .title {
        font-size: 4.1rem;
        font-weight: normal;
        font-family: "Oswald";
        margin-bottom: 1.2rem;
    }

    li, a, span, strong {
        margin-bottom: 1rem;
        font-size: 2rem;
        line-height: 1.2;
    }

    #Intro {
        padding-bottom: 0;
    }

    #description {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        align-items: center;
        margin-top: 8rem;        

        #DescriptionContent {
            max-width: 40%;
            width: 100%;

            p {
                margin-bottom: 5rem;
                font-size: 2rem;
                line-height: 1.2;        
            }
        }

        #hasHighlightedCustomer {
            margin-left: 10%;
        }
    }

    #sections {
        list-style-type: none;

        .section {
            margin-bottom: 5rem;
        
            ul {
                margin-top: 1rem;
                list-style-position: inside;
                list-style-type: disc;                
            }

            a {
                color: #f76963;
                text-decoration: underline;                
            }

            .SectionHeader {
                &:before, &:after {
                    display: none;
                }
            }

            .hasImage {
                padding-left: 2rem;
                width: 100%;
    
                img {
                    width: 100%;
                }
            }
        }
    }

    .divisor {
        background-attachment: fixed;
        background-image: url(https://s3.us-west-2.amazonaws.com/citydata.media/citydata.ai/images/dist/CITYDATA_background_flow_lines.webp);
        background-repeat: no-repeat;
        background-size: cover;
        content: "";
        display: block;
        height: 6rem;
        margin-bottom: 5rem;
        width: 100%;
        margin-top: 5rem;
    }

    #showcases {        
        #showcasesList {
            width: 60%;
            list-style-type: none;
            margin: 0 auto 20px;

            .showcase {
                margin: 0 0 50px;
                font-size: 0;
                line-height: 0;
                padding: 0;
    
                a {
                    cursor: pointer;
                    outline: none;
                    text-align: center;
                    color: inherit;                    
                }
    
                img {
                    width: 100%;
                }

                h3 {
                    font-size: 20px;
                    margin: 20px 0;
                    font-weight: normal;
                }
                h4 {
                    font-size: 16px;
                    font-weight: normal;
                    text-align: left;
                    width: 85%;
                    margin: 10px auto;
                    line-height: 1.6;
                }
            }
        }

        #showCases_dashboard {
            width: 100%;
            height: 70vh;
        }
    }

    #matching_products {
        .hasProducts {
            width: 100%;
            display: flex;
            justify-content: space-between;

            .product {
                list-style: none;
                padding: 0;
                box-sizing: border-box;
                display: inline-block;
                width: 25%;
                vertical-align: top;

                article {
                    width: 100%;

                    h1 {
                        display: flex;
                        align-items: center;
                        font-size: 4.1rem;
                        line-height: 1.5;
                        text-transform: uppercase;
                        font-family: "Oswald";
                        width: -moz-fit-content;
                        width: fit-content;                        
                    }

                    img {
                        height: 60px;
                        margin-right: 13px;
                    }

                    p {
                        font-size: 2rem;
                        line-height: 1.2;
                        padding-left: 76px;
                        box-sizing: border-box;
                    }                    
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    #Solution #matching_products .hasProducts {
        flex-direction: column;

        .product {
            width: 100%;
            margin-bottom: 60px;            
        }
    }    
}
@media screen and (max-width: 1000px) {
	#Solution {
        #description {
            flex-direction: column;

            #DescriptionContent {
                max-width: 80%;                
            }

            #hasHighlightedCustomer {
                margin-top: 15rem;
                margin-left: 0;
            }
        }

        #sections {    
            section {
                .main {
                    flex-direction: column;
                    .hasImage {
                        width: 100%;
                        padding: 0;
                    }
                }
            }
        }
    }

}