.ProductDescription {
	section {
		width: 100%;

		.main {
			display: flex;
			justify-content: space-between;
			margin-bottom: 5rem;
			.hasContent {
				box-sizing: border-box;
				padding-right: 0rem;
				width: 100%;
				display: flex;
				flex-direction: column;
				justify-content: flex-start;

				> div {
					padding-right: 5rem;
				}

				p {
					margin-bottom: 5rem;
					// font-size: 2.2rem;
					font-size: 2rem;
					line-height: 1.2;
				}
			}
			.hasPicture {
				max-width: 55%;
				width: 100%;
				height: auto;
				display: block;
				img {
					width: 100%;
				}
			}
		}
		.features {
			.hasFeatures {
				overflow: hidden;
			}
			.feature {
				color: #505050;
				display: inline-block;
				line-height: 1.2;
				padding: 3rem 1.5rem;
				position: relative;
				width: 12%;
				z-index: 1;
				vertical-align: top;

				.symbol {
					font-size: 2rem;
					height: 1em;
					margin-left: -0.5rem;
					margin-right: 0.5rem;
					vertical-align: bottom;
					width: 1em;
				}
				strong {
					color: #000;
					font-size: 1.6rem !important;
					font-weight: 400;
				} 
				&:after {
					position: absolute;
					width: 1000%;
					content: '';
					background: rgba(59, 133, 152, 0.03);
					height: 1000%;
					left: 0;
					top: 0px;
					z-index: -1;
				}
			}
		}
	}
}
@media screen and (max-width: 1024px) {
    .ProductDescription .features .hasFeatures {
        display: none;
    }
}
@media screen and (max-width: 1000px) {
	.ProductDescription {
		section {
			.main {
				flex-direction: column;

				.hasContent {
					max-width: 100%;
					margin-bottom: 3rem;
				}
				.hasPicture {
					max-width: 100%;
				}
			}
		}
	}
}