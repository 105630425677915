#News{
    .SectionHeader:before,
    .SectionHeader:after {
        background: none;
        height: 0;        
    }

    .blogContent {
        max-width: 100vw;
        overflow-x: scroll;
        padding-bottom: 2rem;
    }

    .hasPosts {
        width: 1200%;
        padding: 0 1rem 0 0;

        .blogPost {
            width: auto;
            display: inline-block;
            vertical-align: top;  
            margin-right: 3rem;

            img {
                max-width: 100%;
                height: 240px;
                width: auto;
                display: block;
            }

            .title {
                display: block;
                max-width: 400px;
                line-break: auto;

                .link {
                    font-size: 2.8rem;
                    line-height: 1.2;
                    font-weight: normal;
                    color: #1c4362;
                    transition: color .15s ease-in-out;
                }
            }

            &:hover {
                .title .link {
                    color: #73c6bd;
                }
            }
        }
    }
    
    ::-webkit-scrollbar {
        width: 2px;
    }
       
    ::-webkit-scrollbar-track {
        background-color: transparent;
    }
       
    ::-webkit-scrollbar-thumb {
        height: 0px;
        background-color: transparent;
        outline: none;
    }
}
@media screen and (max-width: 500px) {
    #News .hasPosts {
        .blogPost {
            max-width: 35rem;
            overflow: hidden;        

            img {
                max-height: 240px;
                height: initial;
            }
        }        
    }
}