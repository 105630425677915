#Intro{
    padding-bottom: 0rem;
    padding-top: 11.5rem;

    background-color: #fff;
    background-image: url(https://s3.us-west-2.amazonaws.com/citydata.media/citydata.ai/images/dist/CITYDATA-Flow-Lines-Top-Section.webp);
    background-size: 100% auto;
    background-position: 0 6rem;

    > .container {
        align-items: start;
        flex-direction: row-reverse;        
    }

    .half {
        width: 49%;
        
        &#hasBlob {
            overflow: hidden;
        }
    }

    #hasDisclaimer {
        height: 63.5rem;
        display: flex;
        flex-direction: column;
        padding-bottom: 0rem;
        box-sizing: border-box;

        position: relative;
        z-index: 1;
        padding-left: 8rem;

        .hasTitles {
            display: flex;
            align-items: flex-start;
            min-height: 25vh;
            padding: 3rem 0 0rem 0rem;
    
            .title {
                font-size: 9rem;
                width: 1em;
                line-height: 1.1;
                font-family: 'Oswald';
                text-transform: uppercase;
                width: fit-content;
                color: #274864;

                display: flex;
                flex-direction: column;
                min-height: 26.4rem;
                justify-content: flex-end;

                margin-bottom: 4rem;
                margin-top: -4rem;

                span {
                    display: block;
                    font-size: inherit;
                    position: relative;
                    width: fit-content;
                    margin-bottom: 2rem;
                }
            }
        }

        .hasDescription {
            padding: 0 0 6rem 0rem;
            .count {
                font-size: 14rem;
                font-weight: normal;
                margin-bottom: 4rem;
                margin-left: -1rem;                

                .globalCities {
                    font-size: 0.3em;
                }
            }

            .description {
                max-width: 475px;
                font-size: 2.5rem;
                line-height: 1.2;
                min-height: 60px;
                font-weight: normal;                
            }
        }

        .cta {
            background: #97d5ce;
            border-radius: 3px;
            box-sizing: border-box;
            color: #fff;
            font-size: 2.5rem;
            padding: 2rem 2rem;
            text-align: center;
            width: fit-content;
            margin-top: 40px;    
        }
    }

    #blobSVGHolder {
        transform: translate(6rem, 1rem) scale(1.2);
    }
}
@media screen and (max-width: 1000px) {
    #Intro {
        flex-direction: column;

        > .container {
            flex-direction: column;            
        }

        .half {
            width: 100%;
        }

        #hasDisclaimer {
            padding-left: 0;
            height: fit-content;

            .hasTitles {
                padding-top: 0;                
            }

            .hasDescription {
                padding: 0;
                // height: 48px;
                
                .description {
                    max-width: 100%;
                }
            }
        }

        #blobSVGHolder {
            transform: translate(0rem, 0rem) scale(1.2);
        }
    }
}
@media screen and (max-width: 660px) {
    #Intro #hasDisclaimer {
        .hasCTAs {
            display: flex;
            flex-direction: column;

            .cta {
                margin: 5px 0;
                width: 100%;
            }
        }
    }
}
@media screen and (max-width: 628px) {
    #Intro #hasDisclaimer .hasTitles .title {
        font-size: 14.33121vw;
        min-height: 23.4rem;
    }
}
@media screen and (max-width: 500px) {
    #Intro {
        #hasBlob {
            margin-bottom: 10vw;
        }

        #hasDisclaimer {
            margin-bottom: 10vw;
    
    
            .hasTitles {
                min-height: 34.367vw; 
            }
    
            .hasDescription {
                padding-bottom: 0rem;
            }

            .cta {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}